import { FC } from "react";
import { ParametrageResource } from "types";
import MainCard from "components/MainCard";
import { Collapse } from "components";
import { List, Receipt } from "@mui/icons-material";
import { ViewItem2 as ViewItem, ViewList, ViewListItem } from "pages/common";
import { Divider, Grid } from "@mui/material";

type SettingFactureDetailProps = {
  parametrage: ParametrageResource;
};

const SettingFactureDetail: FC<SettingFactureDetailProps> = ({
  parametrage,
}) => {
  const { value } = parametrage;
  return (
    <>
      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Facture"
          subtitle="Paramétrer la facture"
          leftIcon={<Receipt />}
        >
          <ViewList>
            <ViewListItem>
              <Grid item xs={12}>
                <ViewItem label="Mode règlement">
                  {value?.modeReglements?.join(" , ")}
                </ViewItem>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={4}>
                <ViewItem label="Payer à l'ordre de">{value?.porteur}</ViewItem>
              </Grid>

              <Grid item xs={12} md={4}>
                <ViewItem label="Condition paiement">
                  {value?.conditionPaiement}
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={4}>
                <ViewItem label="Directeur générale délégué">
                  {value?.directeurGeneralDelegue}
                </ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>
        </Collapse>
      </MainCard>

      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Détail facture"
          subtitle="Paramétrer les détails de la facture"
          leftIcon={<List />}
        >
          <ViewList>
            <ViewListItem>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <ViewItem label="Raison sociale">
                      {value?.raisonSociale}
                    </ViewItem>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ViewItem label="Siège social">
                      {value?.siegeSocial}
                    </ViewItem>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={4}>
                <ViewItem label="Adresse postale">{value?.adresse}</ViewItem>
              </Grid>
              <Grid item xs={12} md={4}>
                <ViewItem label="Numéro téléphone">{value?.telephone}</ViewItem>
              </Grid>
              <Grid item xs={12} md={4}>
                <ViewItem label="CNC/IFU/NIF/NCC">{value?.ncc}</ViewItem>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ViewItem label="Imprimer par">
                  {value?.imprimeur?.mode === "personne_connectee"
                    ? "Personne connectée"
                    : value?.imprimeur?.nom}
                </ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>
        </Collapse>
      </MainCard>
    </>
  );
};

export default SettingFactureDetail;
