import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button } from "@mui/material";
import { LoadingButton } from "components";
import { useNotification } from "hooks";
import { FC, ReactNode, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AnyObjectSchema } from "yup";

type HocCompomentProps = {
  onSave: (data: any) => Promise<any>;
  onFinished: (data: any) => void;
  onBack: () => void;
  defaultValues?: Record<string, any>;
  showBack?: boolean;
  showSuccessNotification?: boolean;
  onReset?: (data: any) => any;
};

export type WrapperProps = {
  isEditMode: boolean;
  renderButtons: () => ReactNode;
  renderGeneralError: () => ReactNode;
  onBack?: () => void;
  onSubmit: () => void;
};

export function withForm(Wrapper: FC<WrapperProps>, schema?: AnyObjectSchema) {
  const HocCompoment: FC<HocCompomentProps> = ({
    onSave,
    onFinished,
    onBack,
    onReset,
    defaultValues,
    showBack = true,
    showSuccessNotification = true,
  }) => {
    const methods = useForm({
      resolver: schema ? yupResolver(schema) : undefined,
      defaultValues,
      mode: "onChange",
      reValidateMode: "onChange",
    });
    const [saving, setSaving] = useState<boolean>(false);
    const notification = useNotification();

    const onSubmit = (data: any) => {
      setSaving(true);

      onSave(data)
        .then((element) => {
          onFinished(element);
          showSuccessNotification &&
            notification.success({
              title: "Modifications enregistrées",
            });

          if (onReset) {
            methods.reset(onReset(data));
          }
        })
        .catch((e) => {
          if (e.status === 422) {
            Object.keys(e.errors || {}).forEach((key) => {
              methods.setError(key, { message: e.errors[key] });
            });
            notification.error({
              title: "Les données soumises ne sont pas valides",
            });
          } else if (e.status === 400) {
            notification.error({
              title: e.message,
            });
          } else {
            console.error(e);
            notification.error({
              title: "Erreur technique",
              message:
                "Une erreur technique est survenue lors de l'enregistrement",
            });
          }
        })
        .finally(() => {
          setSaving(false);
        });
    };

    const onError = (e: any) => {
      console.error(e);
      notification.warning({
        title: "Les données soumises ne sont pas valides",
      });
    };

    const renderButtons = () => {
      return (
        <>
          <LoadingButton
            onClick={methods.handleSubmit(onSubmit, onError)}
            loading={saving}
            variant="contained"
            disableElevation
          >
            Enregistrer
          </LoadingButton>

          <Button
            color="error"
            variant="contained"
            sx={{ ml: 1 }}
            onClick={onBack}
            disableElevation
          >
            Annuler
          </Button>
        </>
      );
    };

    const renderGeneralError = () =>
      methods.formState.errors["_message"] && (
        <Alert color="error" variant="filled" sx={{ marginBottom: 2 }}>
          {methods.formState.errors["_message"]?.message?.toString() || "-"}
        </Alert>
      );

    const isEditMode = !!methods.watch("id");

    return (
      <FormProvider {...methods}>
        <Wrapper
          isEditMode={isEditMode}
          renderButtons={renderButtons}
          onBack={showBack ? onBack : undefined}
          onSubmit={methods.handleSubmit(onSubmit, onError)}
          renderGeneralError={renderGeneralError}
        />
      </FormProvider>
    );
  };

  return HocCompoment;
}
