import { useState, useEffect } from "react";

// material-ui
import { Theme } from "@mui/material/styles";
import {
  useMediaQuery,
  Button,
  ButtonGroup,
  Grid,
  Stack,
  Typography,
  GridProps,
} from "@mui/material";

// third-party
import { format } from "date-fns";

// project import
import IconButton from "components/@extended/IconButton";

// assets
import {
  AppstoreOutlined,
  LayoutOutlined,
  LeftOutlined,
  PicCenterOutlined,
  RightOutlined,
} from "@ant-design/icons";

import { fr } from "date-fns/locale";

// constant
const viewOptions = [
  {
    label: "Mois",
    value: "dayGridMonth",
    icon: AppstoreOutlined,
  },
  {
    label: "Semaine",
    value: "timeGridWeek",
    icon: LayoutOutlined,
  },
  {
    label: "Jour",
    value: "timeGridDay",
    icon: PicCenterOutlined,
  },
  // {
  //   label: "Agenda",
  //   value: "listWeek",
  //   icon: OrderedListOutlined,
  // },
];

// ==============================|| CALENDAR - TOOLBAR ||============================== //

export interface ToolbarProps {
  date: number | Date;
  view: string;
  onClickNext: () => void;
  onClickPrev: () => void;
  onClickToday: () => void;
  onChangeView: (s: string) => void;
  sx?: GridProps["sx"];
}

const CalendarToolbar = ({
  date,
  view,
  onClickNext,
  onClickPrev,
  onClickToday,
  onChangeView,
  sx,
  ...others
}: ToolbarProps) => {
  const matchDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [viewFilter, setViewFilter] = useState(viewOptions);

  useEffect(() => {
    if (matchDownSM) {
      const filter = viewOptions.filter(
        (item) => item.value !== "dayGridMonth" && item.value !== "timeGridWeek"
      );
      setViewFilter(filter);
    } else {
      setViewFilter(viewOptions);
    }
  }, [matchDownSM]);

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
      spacing={matchDownSM ? 1 : 3}
      {...others}
      sx={{ pb: 3, pt: 3 }}
    >
      <Grid item>
        <Button
          variant="outlined"
          onClick={onClickToday}
          size={matchDownSM ? "small" : "medium"}
        >
          Aujourd'hui
        </Button>
      </Grid>
      <Grid item>
        <Stack
          direction="row"
          alignItems="center"
          spacing={matchDownSM ? 1 : 3}
        >
          <IconButton onClick={onClickPrev} size="small">
            <LeftOutlined />
          </IconButton>
          <Typography variant="h5" color="textPrimary">
            {format(date, "MMMM yyyy", { locale: fr })}
          </Typography>
          <IconButton onClick={onClickNext} size="small">
            <RightOutlined />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item>
        <ButtonGroup
          variant="text"
          color="primary"
          aria-label="outlined button group"
        >
          {viewFilter.map((viewOption) => {
            return (
              <Button
                size="medium"
                key={viewOption.value}
                disableElevation
                variant={viewOption.value === view ? "contained" : "outlined"}
                onClick={() => onChangeView(viewOption.value)}
              >
                {viewOption.label}
              </Button>
            );
          })}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default CalendarToolbar;
