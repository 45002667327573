import { CreditCard } from "@mui/icons-material";
import { filterSousMenu } from "menu-items";
import { UserModule } from "types";
import { LINKS } from "utils/constant";

const sousmenus = [
  {
    id: "beneficiaires",
    title: "Bénéficiaires",
    type: "item",
    url: LINKS.beneficiaires.list,
  },
  {
    id: "paiements",
    title: "Paiements",
    type: "item",
    url: LINKS.paiements.list,
  },
];

export const buildPaiementSurSiteMenu = (userModule: UserModule) => {
  const data = filterSousMenu(userModule, sousmenus);
  if (data.length) {
    return {
      id: "group-paiement",
      type: "group",
      children: [
        {
          id: "menu-paiement",
          title: "Paiement sur site",
          type: "collapse",
          icon: CreditCard,
          children: data,
        },
      ],
    };
  }
  return null;
};
