import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box, Container, Toolbar } from "@mui/material";

// project import
import { Drawer } from "./drawer";
import { Header } from "./header";
import { Footer } from "./footer";
import useConfig from "hooks/useConfig";

// types
import { RootStateProps } from "types/root";
import { openDrawer } from "reducers/menu";
import { LINKS } from "utils/constant";
import { selectUser, setUser } from "reducers";
import { authService } from "services";
import { Loader } from "components/Loader";

import { FullLoading } from "components/loading/Loading";
import { UserModule, UserResource } from "types";
import { LIST_ECRANS } from "pages/utilitaires/roles/form/RoleHabilitationForm";

// ==============================|| MAIN LAYOUT ||============================== //

const MENUS = [
  ...LIST_ECRANS.map(({ code }) => code),
  "lieux",
  "activites",
  "setting-factures",
  "ustocks",
];

/**
 * Construction des modules accessible par l'UT
 * @param userInfo
 * @returns
 */
const buildUserModule = (userInfo: UserResource) => {
  // Initialiser le module en fonction des menus
  const modules = MENUS.reduce((prev, curr) => {
    // On part du principe que rien n'est accessible
    prev[curr] = { canRead: false, canWrite: false };
    return prev;
  }, {} as UserModule);

  userInfo.roles?.forEach((role) => {
    // L'admin peut acceder à tous les menus
    if (role.code === "admin") {
      Object.keys(modules).forEach((key) => {
        modules[key].canRead = true;
        modules[key].canWrite = true;
      });
    } else {
      // Alimenter canRead / canWrite en fonction des droits de l'UT
      Object.entries(role.habilitations || {}).forEach(([key, droits]) => {
        // Si le module existe alors alimenter canRead et canWrite
        if (modules[key]) {
          if (!modules[key].canRead) {
            modules[key].canRead = droits.includes("LECTURE");
          }
          if (!modules[key].canWrite) {
            modules[key].canWrite = droits.includes("ECRITURE");
          }
        }
      });
    }
  });
  return modules;
};

export const MainLayout = () => {
  const theme = useTheme();
  const user = useSelector(selectUser);
  const matchDownLG = useMediaQuery(theme.breakpoints.down("xl"));
  const location = useLocation();
  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  useEffect(() => {
    if (!localStorage.getItem("@burval.token")) {
      setLoading(false);
      return;
    }
    authService
      .userInfo()
      .then((userInfo) => {
        dispatch(setUser({ ...userInfo, modules: buildUserModule(userInfo) }));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  if (loading) {
    return (
      <>
        <Loader />
        <FullLoading />
      </>
    );
  }

  if (!user && location.pathname !== "/initpassword") {
    const from =
      location.pathname !== "/" ? encodeURI(`?from=${location.pathname}`) : "";
    return <Navigate to={`${LINKS.auth.login}${from}`} />;
  }

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          width: "calc(100% - 260px)",
          flexGrow: 1,
          p: { xs: 2, sm: 2 },
        }}
      >
        <Toolbar />
        {container && (
          <Container
            maxWidth="lg"
            sx={{
              px: { xs: 0, sm: 2 },
              position: "relative",
              minHeight: "calc(100vh - 110px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Outlet />
            <Footer />
          </Container>
        )}
      </Box>
    </Box>
  );
};
