import dashboard from "./dashboard";
import { buildClienteleMenu } from "./clients";
import { buildUtilitaireMenu } from "./settings";

import { UserModule, UserResource } from "types";
import { NavItemType } from "types/menu";
import { buildTransportMenu } from "./transport";
import { buildPersonnelMenu } from "./personnels";
import { isPersonnel } from "utils/user.helpers";
import { buildSecuriteMenu } from "./securites";
import { buildReportingMenu } from "./reporting";
import { buildGestionStockMenu } from "./stock";
import { buildPaiementSurSiteMenu } from "./paiments-sur-site";

// ==============================|| MENU ITEMS ||============================== //

export const buildUserMenu = (user: UserResource) => {
  if (!user.modules) {
    return [];
  }

  const { modules } = user;
  return [
    isPersonnel(user) ? dashboard : null,
    buildPersonnelMenu(modules),
    buildClienteleMenu(modules),
    buildReportingMenu(modules),
    buildSecuriteMenu(modules),
    buildTransportMenu(modules),
    buildPaiementSurSiteMenu(modules),
    buildGestionStockMenu(modules),
    buildUtilitaireMenu(modules),
  ].filter((e) => !!e) as NavItemType[];
};

export function filterSousMenu(userModule: UserModule, sousmenus: any[]) {
  return sousmenus.filter((sousmenu) => {
    const _module = userModule[sousmenu.id];
    return _module && (_module.canRead || _module.canWrite);
  });
}
