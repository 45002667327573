import { Stack, Typography } from "@mui/material";
import Avatar from "components/@extended/Avatar";
import { useUser } from "hooks";
import { FC, HTMLAttributes } from "react";
import { clientService, siteService } from "services";
import { CentreResource } from "types/centre.type";
import { ClientResource } from "types/client.type";
import { SelectItem } from "types/form.type";
import { SelectAsyncProps } from "types/select-async.type";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";
import {
  MultiAutoComplete,
  MultiAutoCompleteHookForm,
} from "../MultiAutoComplete";

import { useQuery } from "@tanstack/react-query";

interface ClientItem extends SelectItem {
  logo?: string;
  numero: string;
  heureDebutPassage?: string;
  heureFinPassage?: string;
}

export interface SiteItem extends SelectItem {
  client?: ClientResource;
  centre?: CentreResource;
}

export const SelectClientAsyncForm: FC<SelectAsyncProps> = (props) => {
  const fetchData = async () => {
    const { data } = await clientService.findAll({
      ...(props.queryParams || {}),
    });
    return {
      data: data.map((item) => ({
        label: item.nom,
        value: item.id,
        ...item,
      })),
      hasMore: false,
    };
  };

  return (
    <AutoCompleteAsyncHookForm
      {...props}
      fetchData={fetchData}
      renderOption={(optionProps, _option) => {
        const option = _option as ClientItem;
        return (
          <>
            <Avatar
              src={option.logo}
              sx={{ marginRight: 1, color: "textPrimary" }}
              resource="client"
            />
            <Stack>
              <Typography variant="subtitle1">{option.label}</Typography>
              <Typography color="secondary" variant="caption">
                {option.numero}
              </Typography>
            </Stack>
          </>
        );
      }}
    />
  );
};

export const SelectSiteAsyncForm: FC<SelectAsyncProps> = (props) => {
  const { paysId } = useUser();

  const fetchData = async () => {
    const { data } = await siteService.findAll({
      paysId,
      ...(props.queryParams || {}),
    });
    return {
      data: data.map(({ id, nom, ...rest }) => ({
        label: nom,
        value: id,
        ...rest,
      })),
      hasMore: false,
    };
  };

  return (
    <AutoCompleteAsyncHookForm
      {...props}
      fetchData={fetchData}
      renderOption={(optionProps, _option) => {
        const option = _option as SiteItem;
        return (
          <Stack>
            <Typography variant="subtitle1">{option.label}</Typography>
            <Typography color="secondary" variant="caption">
              {option.client?.nom}
            </Typography>
          </Stack>
        );
      }}
    />
  );
};

const useQuerySites = () => {
  const result = useQuery({
    queryKey: ["sites"],
    queryFn: () =>
      siteService.findAll().then(({ data }) =>
        data.map((site) => ({
          label: site.nom,
          value: site.id,
          site,
        }))
      ),
    staleTime: 1000 * 60 * 5,
  });
  return result;
};

type SelectSitesProps = {
  name: string;
  label: string;
  afterSelected?: (name: string, value: any) => void;
};

const renderSiteOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: SelectItem
) => {
  const { site } = option as any;
  return (
    <Stack>
      <Typography variant="subtitle1">{site.nom}</Typography>
      <Typography color="secondary" variant="caption">
        {site.numero}
      </Typography>
    </Stack>
  );
};

export const SelectSitesAsyncForm: FC<SelectSitesProps> = ({
  name,
  label,
  afterSelected,
}) => {
  const { data } = useQuerySites();

  return (
    <MultiAutoCompleteHookForm
      options={data}
      name={name}
      label={label}
      afterSelected={afterSelected}
      renderOption={renderSiteOption}
    />
  );
};

export const SelectSites: FC<{
  name: string;
  label: string;
  value: SelectItem[];
  afterSelected: (name: string, value: SelectItem[]) => void;
}> = ({ name, label, value, afterSelected }) => {
  const { data } = useQuerySites();

  return (
    <MultiAutoComplete
      name={name}
      label={label}
      setValue={(name, selected) => {
        afterSelected(name, selected);
      }}
      value={value}
      options={data}
      renderOption={renderSiteOption}
    />
  );
};
