import { Article } from "@mui/icons-material";
import { UserModule } from "types";
import { filterSousMenu } from "menu-items";
import { LINKS } from "utils/constant";

const sousmenus = [
  {
    id: "stocks",
    title: "Articles",
    type: "item",
    url: LINKS.stocks.list,
  },
  {
    id: "magasins",
    title: "Magasins",
    type: "item",
    url: LINKS.magasins.list,
  },
  {
    id: "approvisionnements",
    title: "Appros",
    type: "item",
    url: LINKS.approvisionnements.list,
  },
  {
    id: "ventes",
    title: "Ventes",
    type: "item",
    url: LINKS.ventes.list,
  },
  {
    id: "inventaires",
    title: "Inventaires",
    type: "item",
    url: LINKS.inventaires.list,
  },
];

export const buildGestionStockMenu = (userModule: UserModule) => {
  const data = filterSousMenu(userModule, sousmenus);

  if (data.length) {
    return {
      id: "group-gestionstocks",
      type: "group",
      children: [
        {
          id: "menu-gestionstock",
          title: "Stocks",
          type: "collapse",
          icon: Article,
          children: data,
        },
      ],
    };
  }
  return null;
};
