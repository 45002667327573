import { FC } from "react";
import { familleArticleService } from "services";
import { SelectAsyncProps } from "types/select-async.type";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";
import { useQuery } from "@tanstack/react-query";

export const SelectFamilleArticleAsyncForm: FC<SelectAsyncProps> = (props) => {
  const result = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const { data } = await familleArticleService.findAll();
      return data.map((item) => ({
        label: item.designation,
        value: item.id,
      }));
    },
    staleTime: 60 * 60 * 1_000, // Expire au bout d'une heure
  });

  return <AutoCompleteAsyncHookForm {...props} defaultOptions={result.data} />;
};
