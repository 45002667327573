import { FC } from "react";

// material-ui
import { Stack, Typography } from "@mui/material";

// project import

import logo from "assets/images/logo.png";
//import Drapeau from "assets/images/rci.png";

interface Props {
  isIcon?: boolean;
}

const LogoMain: FC = () => {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ marginTop: 1 }}>
      <a href="/">
        <img width="80px" height="65px" src={logo} alt="Burval" />
      </a>
      {/* <p style={{ margin: "0", marginLeft: "105px", position: "absolute" }}>
        <img
          src={Drapeau}
          alt="Côte d'Ivoire"
          title="Côte d'Ivoire"
          style={{ width: "23px" }}
        />
      </p> */}
      <Typography textAlign="center" marginTop={0.75} fontWeight={600}>
        Burval Corporate
      </Typography>
    </Stack>
  );
};

const LogoIcon: FC = () => {
  return <img width="50px" src={logo} alt="Burval" />;
};

export const Logo = ({ isIcon }: Props) => (
  <Stack sx={{ margin: "0 auto" }}>
    {isIcon ? <LogoIcon /> : <LogoMain />}
  </Stack>
);
