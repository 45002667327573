export enum TYPE_MENU {
  group = "group",
  item = "item",
}

export enum REFERENTIEL_TYPE {
  GENRE = "genre",
  CIVILITE = "civilite",
  SITUATION_MATRIMONIALE = "situation_matrimoniale",
  NATURE_CONTRAT = "nature_contrat",
  TYPE_DESSERTE = "type_desserte",
  PROVENANCE_DESSERTE = "provenance_commande",
  ETAT = "etat",
  PRESTATION = "prestation",
  TYPE_VOIE = "type_voie",
  MODE_PAIEMENT = "mode_paiement",
  TYPE_CHANGEMENT = "type_changement",
  PERIMETRE = "perimetre",
  INCIDENT = "incident",
  SERVICE = "service",
}

export enum CODE_REFERENTIEL {
  HOMME = "homme",
  FEMME = "femme",

  MONSIEUR = "monsieur",
  MADAME = "madame",
  MADEMOISELLE = "mademoiselle",

  MARIE = "marie",
  EN_COUPLE = "en_couple",
  CELIBATAIRE = "celibataire",

  CDI = "cdi",
  CDD = "cdd",
  PRESTATAIRE = "prestataire",

  PAIEMENT = "paiement",
  TRANSPORT = "transport",

  INIT = "init",
  EN_COURS = "en_cours",
  LIVRE = "livre",
  TRANSIT = "transit",
  ARCHIVE = "archive",
  TERMINEE = "termine",
  CLOTURE = "cloture",
  A_REPLANIFIER = "a_replanifier",

  AUTOMATIQUE = "automatique",
  MAIL = "mail",
  APPEL_TELEPHONIQUE = "appel_telephonique",

  APPRO = "approvisionnement",
  RAMASSAGE = "ramassage",

  PRESTATION_TRANSPORT = "prestation_transport",
  PRESTATION_CAISSE = "prestation_caisse",
  PRESTATION_ATM = "prestation_atm",
  PRESTATION_VENTE = "prestation_vente",

  PAYE = "paye",
  NON_PAYE = "non_paye",
  ANNULE = "annule",

  FINALISE = "finalise",

  ESPECE = "espece",
  VIREMENT = "virement",
  CHEQUE = "cheque",

  VALIDE = "valide",
}

export enum TYPE_PRODUIT {
  service = "service",
}

export const ETAT_RESOURCE = [
  CODE_REFERENTIEL.EN_COURS,
  CODE_REFERENTIEL.LIVRE,
  CODE_REFERENTIEL.ARCHIVE,
  CODE_REFERENTIEL.TRANSIT,
];

export const ETAT_DESSERTE = [
  CODE_REFERENTIEL.EN_COURS,
  CODE_REFERENTIEL.LIVRE,
  CODE_REFERENTIEL.ARCHIVE,
  CODE_REFERENTIEL.TRANSIT,
];

export const ETAT_INCIDENT = [
  CODE_REFERENTIEL.EN_COURS,
  CODE_REFERENTIEL.CLOTURE,
];

export const ETAT_PAIEMENT = [
  CODE_REFERENTIEL.INIT,
  CODE_REFERENTIEL.VALIDE,
  CODE_REFERENTIEL.FINALISE,
];

export enum CODE_FONCTION {
  CHAUFFEUR = "CHAUF",
  AGENT_DE_GARDE = "GARDE",
  CHEF_DE_BORD = "CBORD",
  CONVOYEUR = "CONVOYEUR",
}
